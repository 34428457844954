<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>基础设置</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/enterprise' }"
          >企业管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>编辑</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info">
      <el-form
        ref="form"
        v-loading="edit"
        :model="form"
        :rules="rules"
        label-width="200px"
        size="mini"
      >
        <el-form-item
          :label="form.type === 1 ? '商户号' : 'AppId'"
          label-position="left"
          prop="mchid"
        >
          <el-input v-model="form.mchid" class="input" style="width: 220px" />
        </el-form-item>

        <el-form-item label="类型：" label-position="left" prop="mchid">
          <el-select v-model="form.type" placeholder="请选择">
            <el-option :value="1" label="微信"></el-option>
            <el-option :value="2" label="支付宝"></el-option>
            <el-option :value="3" label="抖音"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.type !== 2"
          label="证书编号："
          label-position="left"
          prop="cert_no"
        >
          <el-input v-model="form.cert_no" class="input" style="width: 220px" />
        </el-form-item>
        <el-form-item label="公钥：" label-position="left" prop="cert_public">
          <el-input
            v-model="form.cert_public"
            style="width: 300px"
            type="textarea"
          />
        </el-form-item>
        <el-form-item label="私钥：" label-position="left" prop="private_key">
          <el-input
            v-model="form.private_key"
            style="width: 300px"
            type="textarea"
          />
        </el-form-item>
        <el-form-item label="回调地址：" prop="callback_url">
          <el-input v-model="form.callback_url" style="width: 220px" />
        </el-form-item>

        <el-form-item>
          <el-button
            :loading="loading"
            size="small"
            type="primary"
            @click="onSubmit"
            >保存
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            @click="$router.go(-1)"
            >取消
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "add",
  data() {
    return {
      loading: false,
      edit: false,
      form: {
        type: 1,
        cert_no: "",
        cert_public: "",
        private_key: "",
        callback_url: "",
      },
      rules: {
        mchid: [{ required: true, message: "请输入路径", trigger: "blur" }],
        cert_no: [
          { required: true, message: "请输入证书编号", trigger: "blur" },
        ],
        cert_public: [
          { required: true, message: "请输入公钥", trigger: "blur" },
        ],
        private_key: [
          { required: true, message: "请输入私钥", trigger: "blur" },
        ],
        callback_url: [
          { required: true, message: "请输入回调地址", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    ...mapActions("enterprise", ["addEnterpriseMchid", "getEnterpriseMchid"]),
    async onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            this.form.enterprise_id = this.$route.params.id;
            const { res_info } = await this.addEnterpriseMchid(this.form);
            if (res_info !== "ok") return;
            this.$message.success("编辑成功");
            this.$router.go(-1);
          } finally {
            this.loading = false;
          }
        }
      });
    },
    async init() {
      try {
        this.edit = true;
        const { data } = await this.getEnterpriseMchid({
          id: this.$route.params.detailsID,
        });
        this.form = data;
      } finally {
        this.edit = false;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  align-items: center;
}
</style>